
.header {
    box-sizing: border-box;
    width: 100%;
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #CFDDBD;
}

.headerMenu {
    box-sizing: border-box;
    height: var(--header-height);
    display: flex;
    align-items: center;
    flex: 1;
}

.headerActions {
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex: 1;
    z-index: 10;
    padding-left: 16px;
}

.headerLogo {
  max-width: 210px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logoImage {
  width: 100%;
  min-width: 110px;
  height: calc(var(--header-height) - 32px);
}
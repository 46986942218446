.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.master {
  margin-top: 16px;
}
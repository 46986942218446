.box {
  display: flex;
  flex-direction: row;
  margin: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
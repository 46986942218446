.box {
    border: 1px solid;
    border-radius: 8px;
    width: 70px;
    min-width: 70px;
    text-align: center;
    border-color: #f3f3f3;
    user-select: none;
}

.boxItemSelected {
    background-color: #218cac;
    color: #fff;
}

.boxItemUnselected {
    cursor: pointer;
}

.day {
    text-transform: capitalize;
}
.box {
  height: calc(100lvh - var(--header-height));
  overflow: auto;
}

.content {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.saveButton {
  display: flex;
  justify-content: end;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  border: #b9b9b9 solid 1px;
  padding: 16px;
}

.box:hover {
  cursor: pointer;
  background-color: #ececec;
}
.mapBox {
  height: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.map {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mapFrame {
  width: 100%;
  height: 100%;
  border-width: 0px;
  border:0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contactsBox {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: azure;
  align-items: center;
  justify-content: center;
  gap: 48px;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media (max-width: 900px) {
  .mapBox {
    flex-direction: column-reverse;
  }
  .map {
    width: 100%;
    height: 600px;
    padding-bottom: 0px;
  }
  .contactsBox {
    width: 100%;
    height: 600px;
  }
}
.box {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 8px;
}

.masterInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.image {
  max-width: 64px;
  max-height: 64px;
  object-fit: cover;
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}



@media (max-width:720px) {
  
  .box {
    flex-direction: column;
  }

  .masterInfo {
    flex-direction: column;
  }
}
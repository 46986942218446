#price-block {
    display: flex;
    flex-direction: column;
    align-items: end;
}

#price-block-total {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
}

#price-block-total-title {
    font-size: .875rem;
    line-height: 1.375rem;
    font-weight: 400;
    margin: auto 0px;
    text-align: center;
}
#price-block-total-value {
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 700;
    margin: 0px;
}
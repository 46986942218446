

.header {
  position: relative;
}

.headerFavorite {
  position: absolute;
  top: 0;
  left: 0;
}

.headerMenu {
  position: absolute;
  top: 0;
  right: 0;
}

.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-top: 8px;
}
.box {
  display: flex;
  flex-direction: row;
  margin: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  gap: 26px;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.questionnaire {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 36px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;
  overflow: hidden;
}

@media (max-width:500px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  
  .info {
    align-items: center;
  }
}
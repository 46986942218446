@import 'react-big-calendar/lib/sass/styles';

#schedule-panel {
    display: flex;
    justify-content: center;
    padding: 16px;
    position: relative;
}

#schedule-panel-clean {
    all: unset;
}
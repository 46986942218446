.box {
  display: flex;
  padding: 16px;
  justify-content: center;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:720px) {
  
  .box {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
}
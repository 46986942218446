.box {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.noMasters {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
}
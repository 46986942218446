.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}
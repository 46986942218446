.box {
  display: flex;
  justify-content: center;
  padding: 16px;
  margin-bottom: 80px;
}

.cardBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}


.userItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 16px;
}

.userAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.userInfoSelectedText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectedText {
  color: #FF0000;
}
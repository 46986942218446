.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.categoryItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.checks {
  display: flex;
  flex-direction: column;
}
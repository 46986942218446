.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  background-color: #CFDDBD;
  margin: 16px;
  border-radius: 8px;
}

.headerDay {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.headerCount {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 16px;
}

.master {
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  padding: 4px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.record {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
}

.sliderItemBox {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 530px;
  padding-top: 80px;
  margin-bottom: 30px;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
          line-clamp: 9; 
  -webkit-box-orient: vertical;
}

@media (max-width: 500px) {
  .sliderItemBox {
    width: 300px;
  }
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
  border-radius: 20px;
  cursor: pointer;
}

.item:hover {
  background-color: #d4d8dd;
}

.selected {
  background-color: #000000;
  color: #ffffff;
}

.selected:hover {
  background-color: #000000;
}

.unselected {
  background-color: #EAEEF4;
}
#profile-info {
    display: flex;
    justify-content: center;
    padding: 16px;
}

#profile-info-user {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#profile-info-user-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#profile-info-user-info-keys {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 24px;
    padding-right: 16px;
}

#profile-info-user-info-values {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 8px;
    padding-right: 24px;
}
.box {
  display: flex;
  padding: 16px;
  flex: 1;
  flex-direction: column;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:720px) {
  
  .box {
    width: 100%;
    margin: 16px;
    box-sizing: border-box;
  }
}
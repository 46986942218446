.layoutVertical {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100lvh;
}

.page {
  position: relative;
  height: calc(100lvh - var(--header-height));
  background-color: var(--bg-color);
  background-image: url('../../../../public/img/facetime-bg-leaf.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
  top: 0;
  left: 0;
}
.box {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid;
}

.serviceName {
  flex: 1
}

.titleName {
  font-size: 40px;
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 80px;
}

.imageBox {
  max-width: 560px;
  max-height: 672px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center center;
}

.contentBox {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex: 1;
}

.bookBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.serviceTime {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  justify-content: center;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  
  .contentBox {
    padding-top: 24px;
    margin-left: 0;
  }
}

.recomendations {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
  text-decoration: underline;
  cursor: pointer;
}

.recomendations:hover {
  text-decoration: none;
}

@media (max-width: 500px) {
  .serviceTime {
    margin-left: 8px;
  }
  
  .recomendations {
    margin-left: 8px;
  }
}
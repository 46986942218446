.masters {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.masterItemBox {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
}

.selectedMaster {
  border-radius: 16px;
  background-color: #f0f0f0;
}

.disabledMaster {
  opacity: 0.5;
  cursor: auto;
}
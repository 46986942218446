.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}


.codeRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.codeNumbers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character {
  margin: 4px;
  padding: 0px;
  text-align: center;

  border: 1px solid #868686;
  background: transparent;
  font-size: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 0 #e4e2f5;
}

.characterFocused {
  border: 1px solid #868686;
}

.buttons {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
}

.doneBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width:700px) {
  
  .content {
    padding: 16px 0px 0px 0px;
  }

}

@media (max-width:500px) {
  .codeRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
}
.serviceBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.serviceName {
  display: flex;
  flex-direction: column;
}
.box {
  display: flex;
  flex-direction: column;
  max-width: 260px;
  align-items: center;
  gap: 8px;
  background-color: #e9e9e9;
  border-radius: 16px;
  padding: 16px 8px 16px 8px;
  flex-shrink: 0;
  align-self: stretch;
}

.timeBox {
  display: flex;
  flex-direction: column;
}

.masterBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.relative {
  position: relative;
}
@font-face {
  font-family: 'MyFont';
  font-style: normal;
  font-weight: normal;
  src: local('MyFont'), url(../fonts/Gibson-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MyFont';
  font-style: italic;
  font-weight: normal;
  src: local('MyFont'), url(../fonts/Gibson-RegularItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'MyFont';
  font-style: normal;
  font-weight: bold;
  src: local('MyFont'), url(../fonts/gibson-bold.ttf) format('truetype');
}

@font-face {
  font-family: 'MyFont';
  font-style: italic;
  font-weight: bold;
  src: local('MyFont'), url(../fonts/Gibson-BoldItalic.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: 'MyFont'; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

#hubspot-messages-iframe-container {
  visibility: hidden;
}

/*::-webkit-scrollbar {*/
/*  background-color: whitesmoke;*/
/*  width: 10px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background-color: lightgray;*/
/*  width: 10px;*/
/*  border-radius: 5px;*/
/*}*/

/*::-webkit-scrollbar-thumb:hover {*/
/*  background-color: darkgrey;*/
/*  width: 10px;*/
/*  cursor: pointer;*/
/*}*/
.box {
  display: flex;
  flex-direction: column;
}

.caption {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
}

.note {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;
}

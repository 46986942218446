.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 24px 8px 24px;
  border: 1px solid black;
  background-color: white;
  color: black;
}

.box:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}


@media (max-width: 500px) {
  .box {
    padding: 8px 8px 8px 8px;
  }
}
.profile {
  display: flex;
  flex-direction: row;
  max-width: 1166px;
  flex: 1;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width:720px) {
  
  .profile {
    flex-direction: column;
    align-items: center;
  }
}
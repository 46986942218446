.pageLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pageLayoutFullscreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pageFullscreen {
  width: 100%;
  height: calc(100lvh - var(--header-height));
  display: flex;
  flex-direction: column;
}
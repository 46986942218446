.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 16px;
}

.selectBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  width: 300px;
  height: 300px;
  margin: 16px;
  position: relative;
  overflow: hidden;
}

.categoryImage {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transform-origin: center center;
  transition: transform 300ms ease;
}

.item:hover .categoryImage {
    transform: scale(1.2);
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.6) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}


.item:hover .categoryTitle {
  padding-bottom: 24px;
}

.categoryTitle {
  color: white;
  font-size: 20px;
  padding-bottom: 16px;
  transition: all 300ms ease;
}
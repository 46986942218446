.buttons {
  margin-top: 8px;
}

.avatar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.langBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: #eee;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px
}
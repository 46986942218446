.time-item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #f3f3f3;
    border-radius: 8px;
    width: 106px;
    height: 39px;
    min-width: 60px;
    text-align: center;
    cursor: pointer;
}

.time-item-box-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #f3f3f3;
    border-radius: 8px;
    width: 106px;
    height: 39px;
    min-width: 60px;
    text-align: center;
    background-color: #218cac;
    color: white;
}

.time-item-box-text {
    padding: 0px 8px 0px 8px;
    margin: 0px;
    user-select: none;
}
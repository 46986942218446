
.master-list-item {
    display: flex;
    flex-direction: column-reverse;
}

.master-list-item img {
    width: 100%;
    max-width: 400px;
}

.master-list-item-info {
    min-width: 250px;
    max-width: 500px;
    padding-right: var(--medium-padding);
}

@media (min-width: 990px) {
    .master-list-item {
        flex-direction: row;
    }
}
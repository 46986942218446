html, body {
  height: 100lvh;
}

#root {
  height: 100lvh;
}

:root {
    --header-height: 90px;
    --content-padding-left: 16px;
    --small-padding: 8px;
    --medium-padding: 16px;
    --bg-color: #F8F6DD;
}

@media (min-width: 990px) {
    :root {
        --content-padding-left: 32px;
        --small-padding: 16px;
        --medium-padding: 24px;
    }
}

.text600 {
    font-size: 1rem;
    font-weight: 600;
}
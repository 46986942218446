.records {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 16px;
}

.addMore {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.consents {
  padding-top: 8px;
}
.box {
  display: flex;
  flex-direction: column;
}

.filterBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}
.box {
  display: flex;
  flex-direction: column;
}

.boxInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.service {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
    flex: 1;
}

@media (max-width:500px) {
  
  .boxInfo {
    flex-direction: column;
    padding: 16px 8px 16px 8px;
  }

}
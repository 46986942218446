.box {
  min-height: 200px;
  background-color: #151515;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.items {
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.subitem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


@media (max-width:720px) {
  
  .box {
    min-height: 400px;
  }

  .items {
    flex-direction: column;
  }
}
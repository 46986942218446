#service-master-block {
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
    border-radius: 8px;
    padding-bottom: 8px;
}

#service-master-block-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}

#service-master-block-masters {
    padding-left: 16px;
    padding-right: 16px;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}

.title {
  padding-top: 24px;
}

.list {
  max-width: 1024px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}
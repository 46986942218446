
.service-list-item {
    min-width: 240px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    width: 100%;
    max-width: 1210px;
    margin: 0 auto;
    background-color: white;
}

.service-list-item img {
    width: 100%;
    max-width: 200px;
    padding: 24px;
    margin: 0 auto;
    text-align: center;
}

.service-list-item-info {
    min-width: 160px;
    width: 100%;
    background-color: white;
    margin-top: 0px;
    margin-bottom: 24px;
}

.service-list-item-title {
    font-size: 24px;
    padding: 0px 24px 0px 24px;
    margin: 0px;
}

.service-list-item-desc {
    font-size: 16px;
    padding: 8px 24px 0px 24px;
    margin: 0px;
}

.service-list-item-pricebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px 0px 24px 0px;
    border-radius: 0px 0px 16px 16px;
    background-color: #292929;
}

.service-list-item-values {
    display: flex;
    flex-direction: row;
    justify-content: center;   
}

.service-list-item-values-box {
    padding-left: 16px;
    padding-right: 16px;
}

.service-list-item-values-title {
    font-size: 14px;
    color: #ffffff;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
}
.service-list-item-values-value {
    font-size: 18px;
    color: #e1a83b;
    font-weight: 900;
    text-transform: uppercase;
}

@media (min-width: 990px) {
    .service-list-item {
        flex-direction: row;
        width: 100%;
    }

    .service-list-item img {
        width: 100%;
        max-width: 200px;
        padding: 24px;
        text-align: center;
    }

    .service-list-item-info {
        min-width: 160px;
        width: 100%;
        background-color: white;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .service-list-item-title {
        padding: 0px 16px 0px 0px;
    }
    
    .service-list-item-desc {
        padding: 8px 16px 0px 0px;
    }

    .service-list-item-pricebox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 228px;
        width: auto;
        padding: 24px 16px 24px 16px;
        border-radius: 0px 16px 16px 0px;
        background-color: #292929;
    }

    .service-list-item-values {
        display: flex;
        flex-direction: row;
        justify-content: center;   
        padding-bottom: 24px;
    }
}
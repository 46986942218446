.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.checks {
  display: flex;
  flex-direction: column;
}
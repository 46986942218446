.timeBox {
  display: flex;
  flex-direction: column;
}

@media (max-width:720px) {
  
  .timeBox {
    flex-direction: row;
    gap: 16px;
  }

}
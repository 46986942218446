.notFoundPage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notFoundPage p {
    color: white;
    font-size: 32px;
    margin: 0;
}
#country-select-flag {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #000000;
}

#country-select-list-item {
    border: 1px solid #000000;
}

.selectedText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}
.box {
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: var(--header-height);
}

.content {
  width: 100%;
  height: calc(100vh - var(--header-height));
  display: flex;
  align-items: center;
  justify-content: center;
}

.topMenuBox {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.topMenuItem {
  justify-content: start;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 1.25rem;
}

.topMenuSubItem {
  padding-left: 16px;
}

.topMenuBox a {
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  list-style-type: none;
}

.topMenuBox a:link {
  text-decoration: none;
}

.topMenuBox a:visited { text-decoration: none; }


.topMenuBox a:hover { 
  color: #918a7a; 
}

.topMenuBox a:active { text-decoration: none; }

.topMenuItemDialog {
  cursor: pointer;
  margin: 0px;
}

.topMenuItemDialog:hover {
  color: #918a7a; 
}

.buttons {
  margin-top: 8px;
}

.avatar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}
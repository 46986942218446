
.servicesPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.servicesList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.servicesList li {
    max-width: 1200px;
    padding-top: 16px;
    margin: 0px;
    list-style: none;
}

@media (max-width: 500px) {
  
  .servicesList {
    padding-left: 16px;
    padding-right: 16px;
  }

}

.topMenuBox {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: var(--medium-padding);
    padding-right: var(--medium-padding);
}

.topMenuItem {
    padding-left: var(--small-padding);
    justify-content: flex-end;
}

.topMenuBox a {
    color: #000000;
    padding-top: 8px;
    padding-bottom: 8px;
}

.topMenuBox a:link {
    text-decoration: none;
}

.topMenuBox a:visited { text-decoration: none; }


.topMenuBox a:hover { 
    color: #918a7a; 
}

.topMenuBox a:active { text-decoration: none; }

.topMenuBoxServices {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    background-color: #f6f6f6;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.topMenuBoxServices li {
    height: 40px;
    padding: 0px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topMenuBoxServices li:hover {
    background-color: #eeeeee;
    cursor: pointer;
}

.topMenuButton {
  display: none;
  height: var(--header-height);
}

.topMenuFullscreen {
  position: fixed;
  width: 100%;
  height: 100lvh;
  top: 0;
  left: 0;
  background-color: #CFDDBD;
  z-index: 100;
}

@media (max-width: 1500px) {
  .topMenuBox {
    display: none;
  }

  .topMenuButton {
    display: flex;
  }
}

.sliderBox {
  width: 100%;
  height: 580px;
}

.box {
  display: flex;
  flex-direction: column;
}

.reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.services {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.serviceItemBox {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}

.serviceInfo {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.serviceTitles {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.serviceTitles {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.selectedService {
  border-radius: 16px;
  background-color: #f0f0f0;
}

.disabledService {
  opacity: 0.5;
  cursor: auto;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  gap: 24px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  flex: 1;
}

.imageBox {
  flex: 1;
}

.image {
  width: 100%;
  border-radius: 8px;
}

.boxCall {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
}


@media (max-width: 720px) {
  
  .box {
    flex-direction: column;
    padding: 16px;
    margin-top: 16px;
  }

}
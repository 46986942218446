.box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #F7F7F8;
  padding: 8px 16px;
  border-radius: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

@media (max-width:720px) {
  
  .row {
    max-width: 300px;
    flex-direction: column;
    padding: 16px 8px 16px 8px;
  }

}
.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.namePhone {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.namePhone div {
  flex-grow: 1;
}

.send {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 24px;
}

@media (max-width: 500px) {

  .namePhone {
    flex-direction: column;
  }

}

.scheduleRecordItem {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
}

.recordNotes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
    flex: 1;
}
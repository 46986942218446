.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-left: var(--medium-padding);
  padding-right: var(--medium-padding);
}

.title {
  padding-left: 8px;
}
.videoBox {
  width: 100%; 
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bgVideo {
object-fit: cover; 
background-size: cover; 
width: 100%; 
height: 100%;
}

.videoContent {
position: absolute;
top: 0;
left: 0;
width: 100%; 
height: 100%;
background-color: #00000088;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white;
}

.about {
position: relative;
z-index: 0;
max-width: 1176px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
justify-content: center;
gap: 16px;
padding: 24px;
margin-top: 36px;
}

.aboutText {
position: relative;
width: 40%;
padding: 24px;
border: solid 1px black;
z-index: 2;
}

.aboutText p:not(:nth-child(1)) {
position: relative;
left: 0px;
}

.aboutImage {
position: relative;
left: calc(-3%);
display: flex;
width: 40%;
z-index: 1;
}

.aboutImage img {
width: 100%;
max-width: 500px;
}

@media (max-width: 900px) {
.aboutText {
  width: 100%;
}
.aboutImage {
  width: 100%;
  left: 0;
}
.aboutImage img {
  max-width: 100%;
}
}


.infoBlocksBox {
display: flex;
flex-direction: row;
gap: 48px;
flex-wrap: wrap;
justify-content: center;
margin-top: 36px;
padding: 16px;
box-sizing: border-box;
}

.infoBlock {
width: 360px;
display: flex;
flex-direction: column;
}

.infoBlockText {
height: 120px;
}


@media (max-width: 815px) {
.infoBlockText {
  height: 100%;
}
}

.infoBlockImage {
margin-top: 36px;
}

.pageFooter {
margin-top: 0;
margin-bottom: 80px;
}

@media (max-width: 410px) {
.infoBlocksBox {
  width: 100%;
}
.aboutImage {
  width: 100%;
  left: 0;
}
.infoBlockImage {
  max-width: 100%;
}
}

.featuredBox {
display: flex;
flex-direction: column;
width: 100%;
max-width: 1208px;
padding: 16px;
box-sizing: border-box;
margin-top: 36px;
}